import _toConsumableArray from "D:/workspace/emind-ui-1.0/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import $ from 'jquery';
import moment from 'moment';
import LuckyExcel from 'luckyexcel';
import datePickerLocale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import axios from 'axios';
export default {
  name: 'Home',
  data: function data() {
    return {
      load: true,
      accept: '.xlsx',
      okButtonProps: {
        props: {
          loading: false
        }
      },
      fileList: [],
      wrapperCol: {
        span: 18
      },
      labelCol: {
        span: 6
      },
      keyboard: false,
      centered: true,
      uploadFormShow: false,
      moment: moment,
      LuckyExcel: LuckyExcel,
      datePickerLocale: datePickerLocale,
      dateFormat: 'YYYY-MM-DD',
      stationStartRunTime: moment(localStorage.getItem("enableOperationDate"), "YYYY-MM-DD"),
      search: {
        stationId: localStorage.getItem('stationId'),
        startMonth: moment().subtract(10, 'month'),
        endMonth: moment(),
        startTime: moment().subtract(1, 'month'),
        endTime: moment().subtract(1, 'days'),
        length: 10000000000,
        page: 1,
        typeFlag: '1',
        startIndex: 0
      },
      options: {
        container: 'luckysheet',
        //luckysheet为容器id
        title: "",
        // 工作簿名称
        lang: "zh",
        // 设定表格语言 国际化设置，允许设置表格的语言，支持中文("zh")和英文("en")
        plugins: ['chart'],
        allowCopy: false,
        // 是否允许拷贝
        showtoolbar: true,
        // 是否显示工具栏
        showinfobar: false,
        // 是否显示顶部信息栏
        showsheetbar: true,
        // 是否显示底部sheet页按钮
        showstatisticBar: false,
        // 是否显示底部计数栏
        sheetBottomConfig: false,
        // sheet页下方的添加行按钮和回到顶部按钮配置
        allowEdit: true,
        // 是否允许前台编辑
        enableAddRow: false,
        // 允许增加行
        enableAddCol: false,
        // 允许增加列
        userInfo: false,
        // 右上角的用户信息展示样式
        showRowBar: true,
        // 是否显示行号区域
        showColumnBar: true,
        // 是否显示列号区域
        sheetFormulaBar: false,
        // 是否显示公式栏
        enableAddBackTop: false,
        //返回头部按钮
        rowHeaderWidth: 46,
        columnHeaderHeight: 30,
        row: 30,
        column: 20,
        showtoolbarConfig: {
          undoRedo: true,
          //撤销重做，注意撤消重做是两个按钮，由这一个配置决定显示还是隐藏
          paintFormat: true,
          //格式刷
          currencyFormat: true,
          //货币格式
          percentageFormat: true,
          //百分比格式
          numberDecrease: true,
          // '减少小数位数'
          numberIncrease: true,
          // '增加小数位数
          moreFormats: true,
          // '更多格式'
          font: true,
          // '字体'
          fontSize: true,
          // '字号大小'
          bold: true,
          // '粗体 (Ctrl+B)'
          italic: true,
          // '斜体 (Ctrl+I)'
          strikethrough: true,
          // '删除线 (Alt+Shift+5)'
          underline: true,
          // '下划线 (Alt+Shift+6)'
          textColor: true,
          // '文本颜色'
          fillColor: true,
          // '单元格颜色'
          border: true,
          // '边框'
          mergeCell: true,
          // '合并单元格'
          horizontalAlignMode: true,
          // '水平对齐方式'
          verticalAlignMode: true,
          // '垂直对齐方式'
          textWrapMode: true,
          // '换行方式'
          textRotateMode: true,
          // '文本旋转方式'
          image: true,
          // '插入图片'
          link: true,
          // '插入链接'
          chart: true,
          // '图表'（图标隐藏，但是如果配置了chart插件，右击仍然可以新建图表）
          postil: true,
          //'批注'
          pivotTable: true,
          //'数据透视表'
          function: true,
          // '公式'
          frozenMode: true,
          // '冻结方式'
          sortAndFilter: true,
          // '排序和筛选'
          conditionalFormat: true,
          // '条件格式'
          dataVerification: true,
          // '数据验证'
          splitColumn: true,
          // '分列'
          screenshot: true,
          // '截图'
          findAndReplace: true,
          // '查找替换'
          protection: true,
          // '工作表保护'
          print: false // '打印'

        },
        showstatisticBarConfig: {
          count: false,
          view: false,
          zoom: false
        },
        showsheetbarConfig: {
          add: true,
          //新增sheet
          menu: false,
          //sheet管理菜单
          sheet: true //sheet页显示

        },
        forceCalculation: true //强制计算公式

      }
    };
  },
  mounted: function mounted() {
    var this_ = this;
    var token = localStorage.getItem("currentUser_token");

    if (!token && typeof token != "undefined" && token != 0) {
      this.$router.replace({
        path: '/'
      });
      return;
    }

    var id = localStorage.getItem('stationId');

    if (!id && typeof id != "undefined" && id != 0) {
      this.$router.replace({
        path: '/distribution'
      });
    }

    if (this_.search.startTime.diff(this_.stationStartRunTime, 'days') < 0) {
      this_.search.startTime = this_.stationStartRunTime;
    }

    var Today = function Today() {
      var now = new Date();
      var y = now.getFullYear();
      var m = now.getMonth();
      var d = now.getDate();
      return new Date(y, m, d);
    };

    var today = Today(); // 确保开始时间为今天之前的时间

    if (this.search.startTime >= today) {
      this.search.startTime = moment(today).subtract(1, 'day');
    }

    this.searchFn();
  },
  methods: {
    disabledDate: function disabledDate(current) {
      var this_ = this;
      return current && current.diff(this_.stationStartRunTime, 'days') < 0;
    },
    disabledEndDate: function disabledEndDate(endValue) {
      return new Date().getTime() - 86400000 < endValue.valueOf();
    },
    handleRemove: function handleRemove(file) {
      var index = this.fileList.indexOf(file);
      var newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload: function beforeUpload(file) {
      this.fileList = [].concat(_toConsumableArray(this.fileList), [file]);
      return false;
    },
    uploadCancel: function uploadCancel() {
      this.uploadFormShow = false;
      this.downloadFormShow = false;
      this.fileList = [];
    },
    uploadSectionFile: function uploadSectionFile() {
      var this_ = this; //配置文件

      if (this.fileList.length == 0) {
        this.$message.warning("请选择电费单模板");
        return;
      }

      this_.okButtonProps.props.loading = true;
      var formData = new FormData();
      formData.append('file', this.fileList[0]);
      axios.post('/emind/history/data/settlement/template/upload/' + localStorage.getItem('stationId'), formData, {
        timeout: 100000
      }).then(function (d) {
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code == 0) {
          this_.$message.success("配置文件上传成功");
        } else if (d.data.code == -1) {
          this_.$message.error("配置文件上传失败");
        }

        this_.uploadFormShow = false;
        this_.fileList = [];
        this_.okButtonProps.props.loading = false;
      }).catch(function (error) {
        this_.fileList = [];
        this_.uploadFormShow = false;
        this_.okButtonProps.props.loading = false;

        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    showUploadModal: function showUploadModal() {
      this.uploadFormShow = true;
    },
    searchFn: function searchFn() {
      this.exportFn(false);
    },
    exportFile: function exportFile() {
      this.exportFn(true);
    },
    exportExcel: function exportExcel(url) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var name = arguments.length > 2 ? arguments[2] : undefined;
      var type = arguments.length > 3 ? arguments[3] : undefined;
      var this_ = this;
      return new Promise(function (resolve, reject) {
        axios({
          method: 'post',
          url: url,
          // 请求地址
          data: options,
          // 参数
          responseType: 'blob' // 表明返回服务器返回的数据类型

        }).then(function (response) {
          resolve(response.data);
          var blob = new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          });

          if (blob.size < 200) {
            blob.text().then(function (result) {
              undefined;
              this_.$message.error(result, 2, function () {});
            });
            return;
          }

          var fileName = name + '.xlsx';
          this_.load = false;

          if (type) {
            if (window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, fileName);
            } else {
              var link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              link.click(); //释放内存

              window.URL.revokeObjectURL(link.href);
            }
          } else {
            var file = new File([blob], fileName, {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              lastModified: Date.now()
            });
            LuckyExcel.transformExcelToLucky(file, function (exportJson, luckysheetfile) {
              if (exportJson.sheets == null || exportJson.sheets.length == 0) {
                this_.$message.warning("Failed to read the content of the excel file, currently does not support xls files!", 2, function () {});
                return;
              }

              window.luckysheet.destroy();
              this_.options.showinfobar = false;
              this_.options.data = exportJson.sheets;
              this_.options.title = exportJson.info.name;
              this_.options.userInfo = exportJson.info.name.creator;
              window.luckysheet.create(this_.options);
            });
          }
        }, function (err) {
          if (error.message != '') {
            this_.load = false;
            this_.$message.error(error.message, 2, function () {});
            return;
          }
        }).finally(function () {
          this_.load = false;
        });
      });
    },
    exportFn: function exportFn(type) {
      var this_ = this;
      var data = {
        stationId: localStorage.getItem('stationId'),
        startTime: this_.search.startTime,
        endTime: this_.search.endTime,
        length: this_.search.length,
        page: this_.search.page,
        startIndex: this_.search.startIndex,
        signalId: this_.search.typeFlag
      };

      if (this_.search.typeFlag == 2) {
        if (this.search.startMonth != null && this.search.endMonth != null && this.search.startMonth.toDate().getTime() > this.search.endMonth.toDate().getTime()) {
          this.$message.error('结束时间早于起始时间，请重新设置查询时间！');
          return;
        } else {
          var dataTime = '';

          if (this_.search.startMonth != null) {
            dataTime = this_.search.startMonth.format('YYYY-MM') + "-01 00:00:00";
            data.startTime = dataTime;
          } else {
            data.startTime = '1970-01-01 00:00:00';
          }

          if (this_.search.endMonth != null) {
            dataTime = moment(this_.search.endMonth.format('YYYY-MM') + "-01 00:00:00").subtract(-1, 'month');
            dataTime = dataTime.subtract(1, 'days').format('YYYY-MM-DD') + " 00:00:30";
            data.endTime = dataTime;
          } else {
            if (this_.search.startTime.toDate().getTime() > new Date().getTime()) {} else {
              data.endTime = moment().subtract(-1, 'month').format('YYYY-MM') + "-01 00:00:00";
            }
          }
        }

        data.name = '%Y-%m';
      } else if (this_.search.typeFlag == 3) {
        data.startTime = moment().subtract(10, 'year').format('YYYY') + "-01-01 00:00:00";
        data.endTime = moment().subtract(-1, 'month').format('YYYY-MM') + "-01 00:00:00";
        data.name = '%Y';
      } else {
        if (this.search.startTime != null && this.search.endTime != null && this.search.startTime.toDate().getTime() > this.search.endTime.toDate().getTime()) {
          this.$message.error('结束时间早于起始时间，请重新设置查询时间！');
          return;
        } else {
          var dataTime = '';

          if (this_.search.startTime != null) {
            dataTime = this_.search.startTime.format('YYYY-MM-DD ') + "00:00:00";
            data.startTime = dataTime;
          } else {
            data.startTime = '1970-01-01 00:00:00';
          }

          if (this_.search.endTime != null) {
            dataTime = this_.search.endTime.format('YYYY-MM-DD ') + "23:59:59";
            data.endTime = dataTime;
          } else {
            if (this_.search.startTime.toDate().getTime() > new Date().getTime()) {} else {
              data.endTime = moment().format('YYYY-MM-DD ') + "23:59:59";
            }
          }
        }

        data.name = '%Y-%m-%d';
      }

      this_.load = true;
      var url = '/emind/history/data/settlement/report/export';
      this_.exportExcel(url, data, localStorage.getItem('stationName') + '-电费单', type);
    }
  }
};